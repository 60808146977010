import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';

@Injectable()
export class httprequestService {
    constructor(private http: HttpClient) { }

    sendGet(url: string) {
      return this.http.get<any>('https://api.vicinity.bavenir.eu/api/' + url)
      .pipe(
        map(response => {
          // console.log(response)
          return response;
        }),
        catchError(this.handleError)
      );
    }

    sendPost(url: string, body: object) {
      return this.http.post<any>('https://api.vicinity.bavenir.eu/api/'  + url, body)
      .pipe(
        map(response => {
          // console.log(response)
          return response.message;
        }),
        catchError(this.handleError)
      );
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError( { error: true } );
    };

}
