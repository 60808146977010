import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material"; // Needed to update table
import { TooltipPosition } from "@angular/material/tooltip";

export interface TableAttribute {
  imlast: boolean;
  deviceName: string;
  deviceCount: number;
  interactionCount: number;
  rate: number;
}

@Component({
  selector: "app-calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.css"],
})
export class CalculatorComponent implements OnInit {
  // Table
  public tableColumns: string[] = [
    "device",
    "dcount",
    "icount",
    "rate",
    "msgmonth",
    "action",
  ];
  public fieldArray: Array<TableAttribute> = [
    {
      imlast: true,
      deviceName: "",
      deviceCount: 0,
      interactionCount: 0,
      rate: 0,
    },
  ];
  private newAttribute: any = {};
  // Pricing plan
  public msgPerMin: number = 2;
  // API results
  // private listOfDevices: Array<string> = [];
  // Calculations
  public grantTotalEur: number = 0;
  public grantTotalEurDataIntensive: number = 0;
  public grantTotalMsg: number = 0;
  public pricingLayer: string = "";
  public isDataIntensive: boolean = false;
  // Tooltip positions
  public positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];

  @ViewChild("table", { static: false }) table: MatTable<any>; // Needed to update table

  constructor() {}

  ngOnInit() {}

  addFieldValue() {
    if (this.validateInput()) {
      this.fieldArray.unshift(this.newAttribute);
      this.newAttribute = {
        imlast: false,
        deviceCount: null,
        interactionCount: null,
        rate: null,
        deviceName: null,
      };
      // Recalculate totals
      this.grantTotalMsg = this.getTotalMessages();
      this.calculatePrice();
      this.table.renderRows(); // Updates table
    }
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
    this.grantTotalMsg = this.getTotalMessages();
    this.calculatePrice();
    this.table.renderRows(); // Updates table
  }

  /** Gets the total messages */
  getTotalMessages() {
    return this.fieldArray
      .map((t) => t.deviceCount * t.interactionCount * t.rate * 30 * 24)
      .reduce((acc, value) => acc + value, 0);
  }

  validateInput() {
    var ret = true; // Input is valid
    if (this.newAttribute.rate > 120 || this.newAttribute.rate < 1) {
      this.newAttribute.rate = null;
      ret = false;
    }
    if (this.newAttribute.deviceCount < 1) {
      this.newAttribute.deviceCount = null;
      ret = false;
    }
    if (this.newAttribute.interactionCount < 1) {
      this.newAttribute.interactionCount = null;
      ret = false;
    }
    return ret;
  }

  calculatePrice() {
    var n = this.grantTotalMsg;
    if (n < 200000 && !this.isDataIntensive) {
      this.grantTotalEur = 0;
      this.grantTotalEurDataIntensive = 0;
      this.pricingLayer = "Developer";
    } else if (n < 1000000) {
      this.grantTotalEur = 10;
      this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 50) / 1000000;
      this.pricingLayer = "Basic 1M";
    } else if (n < 2000000) {
      this.grantTotalEur = 14;
      this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 35) / 1000000;
      this.pricingLayer = "Basic 2M";
    } else if (n < 5000000) {
      (this.grantTotalEur = 27), 5;
      this.grantTotalEurDataIntensive =
        this.grantTotalEur + (n * 27.5) / 1000000;
      this.pricingLayer = "Medium 5M";
    } else if (n < 10000000) {
      this.grantTotalEur = 40;
      this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 20) / 1000000;
      this.pricingLayer = "Medium 10M";
    } else if (n < 20000000) {
      this.grantTotalEur = 75;
      this.grantTotalEurDataIntensive =
        this.grantTotalEur + (n * 18.75) / 1000000;
      this.pricingLayer = "Medium 20M";
    } else if (n < 50000000) {
      this.grantTotalEur = 175;
      this.grantTotalEurDataIntensive =
        this.grantTotalEur + (n * 17.5) / 1000000;
      this.pricingLayer = "Large 50M";
    } else if (n < 100000000) {
      this.grantTotalEur = 250;
      this.grantTotalEurDataIntensive =
        this.grantTotalEur + (n * 12.5) / 1000000;
      this.pricingLayer = "Large 100M";
    } else if (n < 200000000) {
      this.grantTotalEur = 490;
      this.grantTotalEurDataIntensive =
        this.grantTotalEur + (n * 12.25) / 1000000;
      this.pricingLayer = "Large 200M";
    } else if (n < 500000000) {
      this.grantTotalEur = 1200;
      this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 12) / 1000000;
      this.pricingLayer = "XXL 500M";
    } else {
      this.grantTotalEur = 0;
      this.grantTotalEurDataIntensive = 0;
      this.pricingLayer = "Custom plan";
    }
    // } else if(n < 500000000) {
    //   this.grantTotalEur = 1200;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 12 / 1000000);
    //   this.pricingLayer = "Layer 9";
    // } else if(n < 1000000000) {
    //   this.grantTotalEur = 2250;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 11.25 / 1000000);
    //   this.pricingLayer = "Layer 10";
    // } else if(n < 2000000000) {
    //   this.grantTotalEur = 4400;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 11 / 1000000);
    //   this.pricingLayer = "Layer 11";
    // } else if(n < 5000000000) {
    //   this.grantTotalEur = 10750;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 10.75 / 1000000);
    //   this.pricingLayer = "Layer 12";
    // } else if(n < 10000000000) {
    //   this.grantTotalEur = 20000;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 10 / 1000000);
    //   this.pricingLayer = "Layer 13";
    // } else if(n < 20000000000) {
    //   this.grantTotalEur = 38000;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 9.5 / 1000000);
    //   this.pricingLayer = "Layer 14";
    // } else {
    //   this.grantTotalEur = 90000;
    //   this.grantTotalEurDataIntensive = this.grantTotalEur + (n * 9 / 1000000);
    //   this.pricingLayer = "Layer 15";
    // }
  }
}
