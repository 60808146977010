import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit{

  constructor() {
  }

   ngOnInit() {
   }


}
