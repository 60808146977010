import { Component, OnInit } from '@angular/core';
import { httprequestService } from '../../_services/httprequest.service';



@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(
    private httpService: httprequestService
  ) { }

  ngOnInit() {
    // this.httpService.sendGet('repository/annotations')
    // .subscribe(
    //   res => {
    //     this.getItems(res.data.device);
    //     // this.getItems(res.data.service);
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // )
  }

}
