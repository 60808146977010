import { Component, OnInit } from '@angular/core';
import { httprequestService } from '../../_services/httprequest.service';

export interface Statistics {
  organisations: number;
  users: number;
  nodes: number;
  devices: number;
  services: number;
  contracts: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public statistics: Statistics;

  constructor(
    private httpService: httprequestService
  ) { }

    ngOnInit() {
      // this.httpService.sendGet('public/statistics')
      // .subscribe(
      //   data => {
      //     console.log(data)
      //     this.statistics = data.message;
      //   },
      //   error => {
      //     console.log(error);
      //   }
      // )
    }

}
