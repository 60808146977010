import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { httprequestService } from '../../_services/index';
import { timer } from 'rxjs';

export interface MySelection {
  plan: string;
  name: string;
  email: string;
  comments: string;
}

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  // public options: string[] = [ "Developer", "Basic 1M", "Basic 2M", "Medium 5M", "Medium 10M", "Medium 20M", "Large 50M", "Large 100M", "Large 200M", "XXL 500M", "Custom Plan" ];
  public selected: MySelection = {plan: null, name: null, email: null, comments: null};
  public email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  public name: FormControl = new FormControl('', [Validators.required]);
  public isSent: boolean = false;
  public isError: boolean = false;

  constructor(
    private httpService: httprequestService
  ) { }

  ngOnInit() {
  }

  validateInput(){
    // if(!this.selected.plan){ return true; }
    // else if(this.email.hasError('required') || this.email.hasError('email')){ return true; }
    // else if(this.name.hasError('required')){ return true; }
    // else { return false; }
    if(this.email.hasError('required') || this.email.hasError('email')){ return true; }
    else if(this.name.hasError('required')){ return true; }
    else { return false; }
  }

  getErrorMessage(x) {
    if(x === 'email'){
      return this.email.hasError('required') ? 'You must enter a email' :
          this.email.hasError('email') ? 'Not a valid email' :
          '';
    } else {
      return this.name.hasError('required') ? 'You must enter a name' : '';
    }
  }

  sendMail(){
    this.selected.name = this.name.value;
    this.selected.email = this.email.value;
    this.httpService.sendPost('public/sales', this.selected)
    .subscribe(
      data => {
        this.email.reset();
        this.name.reset();
        this.selected.plan = null;
        this.selected.comments = null;
        this.isSent = true;
        this.startTimer();
      },
      error => {
        this.email.reset();
        this.name.reset();
        this.selected.plan = null;
        this.selected.comments = null;
        this.isError = true;
        this.startTimer();
      }
    )
  }

  startTimer() {
      timer(5000)
      .subscribe(
          value =>
          {
            this.isError = false;
            this.isSent = false;
          },
          err => console.log(err)
      );
  }


}
