import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppComponent } from "./app.component";
import {
  HomeComponent,
  NavigationComponent,
  FooterComponent,
  PricingComponent,
  IntegrationComponent,
  WhoweareComponent,
  ContactusComponent,
  CatalogueComponent,
  InfrastructureComponent,
} from "./_templates/index";
import { PagenotfoundComponent } from "./_directives/index";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "pricing", component: PricingComponent },
  { path: "integration", component: IntegrationComponent },
  { path: "partners", component: WhoweareComponent },
  { path: "catalogue", component: CatalogueComponent },
  { path: "contact", component: ContactusComponent },
  { path: "infrastructure", component: InfrastructureComponent },
  { path: "**", component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
