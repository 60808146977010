import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeRoutingModule } from './app-routing.module';
// Components, services and directives
import { IntegrationComponent, NavigationComponent, HomeComponent, PricingComponent, FooterComponent, WhoweareComponent, ContactusComponent, CatalogueComponent} from './_templates/index';
import { CarouselComponent } from './_templates/pricing/carousel/carousel.component';
import { CalculatorComponent } from './_templates/pricing/calculator/calculator.component';
import { PricelistComponent } from './_templates/pricing/pricelist/pricelist.component';
import { PagenotfoundComponent } from './_directives/index';
import { httprequestService } from './_services/index';
// Material design components and animations
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule, MatInputModule, MatSelectModule, MatTableModule, MatTabsModule, MatCardModule, MatButtonModule, MatButtonToggleModule, MatIconModule, MatTooltipModule, MatListModule } from '@angular/material';
import { InfrastructureComponent } from './_templates/infrastructure/infrastructure.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PricingComponent,
    HomeComponent,
    IntegrationComponent,
    PagenotfoundComponent,
    NavigationComponent,
    WhoweareComponent,
    CarouselComponent,
    CalculatorComponent,
    PricelistComponent,
    ContactusComponent,
    CatalogueComponent,
    InfrastructureComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    HomeRoutingModule,
    FormsModule,
    NoopAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatListModule,
    MatIconModule
  ],
  providers: [
    httprequestService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
