import { Component, OnInit } from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';

export interface PriceList {
  name: string;
  messages: string;
  price: string;
  dataintensiveprice: string;
  support: string;
}

@Component({
  selector: 'app-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.css']
})
export class PricelistComponent implements OnInit {
// Tooltip positions
public positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
// Table
public tableColumns: string[] = ['name', 'messages', 'price', 'messagesize', 'dataintensiveprice', 'messagesizeintensive', 'support'];
public fieldArray: Array<PriceList> = [
  {
    name: "Development",
    messages: "200K",
    price: "FREE",
    dataintensiveprice: "N/A",
    support: "Community"
  },
  {
    name: "Basic 1M",
    messages: "1M",
    price: "10€",
    dataintensiveprice: "50€",
    support: "Community"
  },
  {
    name: "Basic 2M",
    messages: "2M",
    price: "14€",
    dataintensiveprice: "35€",
    support: "Community"
  },
  {
    name: "Medium 5M",
    messages: "5M",
    price: "27,5€",
    dataintensiveprice: "27,5€",
    support: "Dedicated"
  },
  {
    name: "Medium 10M",
    messages: "10M",
    price: "40€",
    dataintensiveprice: "20€",
    support: "Dedicated"
  },
  {
    name: "Medium 20M",
    messages: "20M",
    price: "75€",
    dataintensiveprice: "18,75€",
    support: "Dedicated"
  },
  {
    name: "Large 50M",
    messages: "50M",
    price: "175€",
    dataintensiveprice: "17,5€",
    support: "Dedicated"
  },
  {
    name: "Large 100M",
    messages: "100M",
    price: "250€",
    dataintensiveprice: "12,5€",
    support: "Dedicated"
  },
  {
    name: "Large 200M",
    messages: "200M",
    price: "490€",
    dataintensiveprice: "12,25€",
    support: "Dedicated"
  },
  {
    name: "XXL 500M",
    messages: "500M",
    price: "1200€",
    dataintensiveprice: "12€",
    support: "Dedicated"
  }];

  constructor() { }

  ngOnInit() {
  }

}
