import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-whoweare',
  templateUrl: './whoweare.component.html',
  styleUrls: ['./whoweare.component.css']
})
export class WhoweareComponent implements OnInit{

  constructor() {
  }

   ngOnInit() {
   }


}
